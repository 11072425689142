import { useEffect, useState } from "react"
import { parse } from "next-useragent"

export default function useBrowser() {
  const [browser, setBrowser] = useState({
    ua: {},
    os: "",
    name: "",
    isClient: false,
    inApp: false
  })

  useEffect(() => {
    const ua = parse(window.navigator.userAgent)
    const os = ua.os.toLowerCase()
    const name = ua.browser.toLowerCase()

    setBrowser({
      ua,
      os,
      name,
      isClient: true,
      inApp: inAppBrowsers.includes(browser)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return browser
}

const inAppBrowsers = [
  "messenger",
  "facebook",
  "instagram",
  "whatsapp",
  "wechat",
  "line",
  "kakaotalk",
  "naver",
  "kik",
  "viber",
  "telegram",
  "snapchat",
  "twitter",
  "linkedin",
  "pinterest",
  "tumblr",
  "reddit",
  "discord",
  "slack",
  "signal",
  "skype",
  "zoom",
  "teams",
  "meet",
  "hangouts",
  "webview"
]
