import { useEffect, useState } from "react"
import {
  usePathData,
  usePropsObject,
  useAppContext,
  useAdminBar
} from "@/lib/contexts/appContext"

import Main from "@/components/wrappers/main"
import Blocks from "@/components/Blocks"
import { ArchiveProvider } from "@/lib/contexts/archiveContext"
import { ArchiveProvider as ArchiveProvider2 } from "@/lib/contexts/archiveContext-2"
import classNames from "classnames"

export function Archive() {
  const pathData = usePathData()
  const adminBar = useAdminBar()
  const [classes, setClasses] = useState([
    "template-" + pathData.template,
    "text-black-100"
  ])

  const object = usePropsObject()
  const appContext = useAppContext()

  useEffect(() => {
    setClasses([...classes, adminBar ? "pt-12 sm:pt-12 md:pt-9" : null])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  const { context, list, maxPage, totalCount, cardType } = object.archive

  if (["calendar", "natursnokarna", "site"].includes(context)) {
    return (
      <Main className={classNames(classes)}>
        <ArchiveProvider2 {...object.archive}>
          <Blocks blocks={pathData.content} />
        </ArchiveProvider2>
      </Main>
    )
  }

  return (
    <Main className={classNames(classes)}>
      <ArchiveProvider
        object={object}
        context={context}
        list={list}
        maxPage={maxPage}
        totalCount={totalCount}
        cardType={cardType}
        page={object.page}
        phrase={appContext.phrase}
        selected={appContext.selected}>
        <Blocks blocks={pathData.content} />
      </ArchiveProvider>
    </Main>
  )
}

export default Archive
