import Icon from "@/common/icons/arrow"
import styles from "./paging.module.css"
import classNames from "classnames"

export default function Paging({
  currentPage,
  maxPage,
  elemRef,
  setPage,
  scrollMargin = 0
}) {
  const doPage = (pageNumber) => {
    window.scrollTo({
      top: elemRef?.current?.offsetTop + scrollMargin,
      behavior: "smooth"
    })

    setPage(pageNumber)
  }

  const getPages = () => {
    let pages = []

    pages = Array(maxPage)
      .fill()
      .map((ignore, n) => 1 + n)

    // Too few to ellipsis
    if (pages.length < 6) {
      return pages
    }

    if (currentPage > 4) {
      pages = pages.filter((n) => n === 1 || n >= currentPage - 2)
    }

    if (maxPage - currentPage > 2) {
      pages = pages.filter(
        (n) => n <= currentPage || n <= currentPage + 2 || n === maxPage
      )
    }

    return pages
  }

  const Arrow = ({ page, className, word }) => {
    if (page < currentPage && currentPage === 1) {
      return (
        <div className={classNames(styles.arrow, "previous dim")}>
          <div className="circle">
            <Icon className="w-4 pointer-events-none" />
          </div>
          <span>{word}</span>
        </div>
      )
    }

    if (page > currentPage && currentPage >= maxPage) {
      return (
        <div className={classNames(styles.arrow, "next dim")}>
          <div className="circle">
            <Icon className="w-4 pointer-events-none" />
          </div>
          <span>{word}</span>
        </div>
      )
    }

    return (
      <div
        className={classNames(styles.arrow, className)}
        onClick={() => doPage(page)}>
        <div className="circle">
          <Icon className="w-4 pointer-events-none" />
        </div>
        <span>{word}</span>
      </div>
    )
  }

  const pageSpread = getPages()

  if (pageSpread.length < 2) {
    return null
  }

  return (
    <nav className={styles.nav}>
      <Arrow page={currentPage - 1} word={"Föregående"} className="previous" />
      <div className="items-center hidden md:flex">
        {pageSpread.map((page, key) => {
          let ellipsis =
            page + 1 === pageSpread[key + 1] ||
            pageSpread[key + 1] === undefined ? null : (
              <span className="px-1">...</span>
            )

          return (
            <div className="flex" key={"page-" + key}>
              <div
                className={classNames([
                  styles.number,
                  { "is-current": currentPage === page }
                ])}
                onClick={() => doPage(page)}>
                {page}
              </div>
              {ellipsis}
            </div>
          )
        })}
      </div>
      <Arrow page={currentPage + 1} word={"Nästa"} className="next" />
    </nav>
  )
}
