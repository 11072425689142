import Block from "./Block"
import { usePathData } from "@/lib/contexts/appContext"

export default function Blocks({ blocks, tracking, settings = {} }) {
  const { path } = usePathData()

  return blocks.map((block, index) => {
    // FIXME add drill down generic settings here?
    const key = [path, block?.name, index].join("-")
    return (
      <Block
        tracking={tracking}
        block={block}
        key={key}
        settings={settings[block.name]}
      />
    )
  })
}
