import { useState } from "react"
import {
  useCopy,
  usePathData,
  usePropsObject,
  useMeta
} from "@/lib/contexts/appContext"
import useBrowser from "@/lib/hooks/useBrowser"
import { templateMapping } from "@/lib/templateMapping"
import { fetchContent } from "@/lib/api/fetchContent"
import NotFound from "@/components/base/notFound"

import Popup from "./popup/popup"
import Header from "./header/header"
import Footer from "./footer/footer"
import Sidebar from "@/components/blocks/theme/campaign-sidebar/sidebar"
import { useEffect } from "react"

export function Layout() {
  const { theme, sidebarContent } = usePropsObject()
  const [isCustom, setCustom] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [content, setContent] = useState([])
  const meta = useMeta()
  const pathData = usePathData()
  const copy = useCopy()
  const browser = useBrowser()

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const { hasBankID } = meta

      if (hasBankID && browser.os === "android" && browser.inApp) {
        setCustom(true)
        setInProgress(true)

        fetchContent("inAppAndroid").then((response) => {
          setInProgress(false)
          setContent(response.content ?? [])
        })
      }
    }
    return () => {
      isMounted = false
      setCustom(false)
      setContent([])
      setInProgress(false)
    }
  }, [meta, browser])

  const Custom = templateMapping["custom"]
  const Template = templateMapping[pathData.template] ?? NotFound
  const hideFooter = theme?.hideFooter ?? false
  const hasSidebar = pathData.template === "campaign" && Boolean(sidebarContent)

  return (
    <>
      <Popup data={copy?.general?.popUp} />
      <Header hasSidebar={hasSidebar} />
      {!isCustom && <Template />}
      {isCustom && <Custom content={content} loading={inProgress} />}
      {!hideFooter && <Footer hasSidebar={hasSidebar} />}
      {hasSidebar && <Sidebar block={sidebarContent} />}
    </>
  )
}
